import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`👋`}</h1>
    <h2>{`Hello. Halo. नमस्ते. こんにちは。`}</h2>
    <p>{`My name is `}<strong parentName="p">{`Sid Mansukhani`}</strong>{`. I am a husband, father and developer based in Melbourne, Australia. I am passionate about software development and love to experiment with new technologies. I speak 5 human languages and several computer languages. In my free time, I love spending time with my family, watching movies, trying out new restaurants and travelling.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      